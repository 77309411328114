.container {
  display: flex;
  width: auto;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  padding: 0 20px;
  gap: 8px;
}

.logoPlacement {
  aspect-ratio: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 14px;
}

.closeButtonPreview {
  margin-bottom: 3px;
  padding-right: 10px;
}

.block1 {
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.block1 > p {
  position: absolute;
  top: 25px;
  right: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #3A3F62;
  padding-right: 5px;
}

.block2 {
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.block3 {
  aspect-ratio: 1;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.block4 {
  aspect-ratio: 1;
  position: relative;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.block4 > p {
  position: absolute;
  top: 32px;
  right: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #3A3F62;
}

.greetingMessagePreview {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  height: 48px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 29px 0px #1345BA24;
  margin-left: 40px;
  margin-bottom: 108px;
  margin-top: 36px;
  padding: 0 10px;
  gap: 8px;
}

.message {
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  margin-bottom: 0;
  padding-left: 4px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.previewLogo {
  width: 72px;
  height: 72px;
  border-radius: 100px;
  background-color: #ACB0C0;
  box-shadow: 0px 4px 12px -2px #050E1A4D;
}

.closeButtonPreviewRightSide {
  margin-bottom: 3px;
  margin-right: -8px;
}

.block1RightSide {
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.block2RightSide {
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.block2RightSide > p {
  position: absolute;
  top: 20px;
  right: 31px;
  font-size: 14px;
  font-weight: 400;
  color: #3A3F62;
  padding-right: 5px;
}

.block3RightSide {
  aspect-ratio: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.block3RightSide > p {
  position: absolute;
  top: 32px;
  right: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #3A3F62;
}

.block4RightSide {
  aspect-ratio: 1;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}