.greetingMessagePopupSection {
  margin-top: 56px;
  width: 1300px;
}

.subHeaderText {
  margin: 40px 16px 16px 16px;
  color: #616581;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.horizontalLine {
  height: 1px;
  color: #b4b4b7;
}

.greetingMessagePopupSectionContainer {
  display: flex;
}

.logoSection {
  margin-left: 15px;
}

.logoSizeInput {
  width: 174px;
}

.popupPositioningSection {
  margin-left: 15px;
  margin-top: 24px;
}

.alignSection {
  display: flex;
}

.rightCheckbox {
  margin-left: 26px;
}

.spacingSection {
  display: flex;
}

.bottomSpacing {
  width: 174px;
}

.sideSpacing {
  margin-left: 8px;
  width: 174px;
}

.greetingMessagePopupPreviewScreenContainer {
  margin-left: 133px;
}