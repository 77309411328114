@import '../../../../variables';

.container{
  margin-bottom: 80px;
}

.header {
  display: flex;
  align-items: center;
  & > h2 {
    margin-bottom: 0;
  }
}

.subHeader {
  margin-top: 30px;
}

.subHeaderText {
  margin: 40px 16px 16px 16px;
  color: #616581;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.horizontalLine {
  height: 1px;
  color: #b4b4b7;
}

.body {
  margin-top: 20px;
  display: flex;
  gap: 210px;
}

.form{
  width: 320px;
}

.paragraph {
  font-size: 12px;
  margin: 7px 0 0;
  color: $spun-pearl-grey;
}

.switchersContainer{
  margin-top: 32px;
  margin-left: -12px;
}
